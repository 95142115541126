// extracted by mini-css-extract-plugin
export var address = "Home-module--address--ffe16";
export var contact = "Home-module--contact--8a76d";
export var container = "Home-module--container--2d40b";
export var email = "Home-module--email--dbb45";
export var greenSection = "Home-module--greenSection--5064a";
export var logo = "Home-module--logo--778b6";
export var logoWrapper = "Home-module--logoWrapper--eea56";
export var mobile = "Home-module--mobile--f7a6d";
export var name = "Home-module--name--0cd36";
export var nameArticle = "Home-module--nameArticle--546c9";
export var nameArticleWrapper = "Home-module--nameArticleWrapper--7c751";
export var nameCard = "Home-module--nameCard--5cd15";
export var nameCardWrapper = "Home-module--nameCardWrapper--06ef3";
export var nameSection = "Home-module--nameSection--c5f56";
export var peopleTargetImg = "Home-module--peopleTargetImg--217da";
export var photoSection = "Home-module--photoSection--ed568";
export var title = "Home-module--title--aaf20";