import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Logo from "../../static/huntman_logo.svg"
import "../styles/global.css"
import * as styles from "../styles/Home.module.css"

const nameCards = [
  {
    id: 1,
    name: "heejin seo",
    title: "head consultant",
    mobile: "M. 82.10.7182.5446",
    tel: "010-7182-5446",
    // tel1: "+82.10.7182.5446",
    email: "E. seo@huntman.co.kr",
    mailto: "seo@huntman.co.kr",
  },
  {
    id: 2,
    name: "jungjun yoon",
    title: "head consultant",
    mobile: "M. 82.10.8642.1390",
    tel: "010-8642-1390",
    email: "E. jjyoon@huntman.co.kr",
    mailto: "jjyoon@huntman.co.kr",
  },
]

const NameCard = ({ card }) => (
  <div className={styles.nameCard}>
    <p className={styles.name}>{card.name}</p>
    <p className={styles.title}>{card.title}</p>
    <a href={`tel:${card.tel}`}>
      <p className={`${styles.contact} ${styles.mobile}`}>{card.mobile}</p>
    </a>
    <a href={`mailto:${card.mailto}`}>
      <p className={`${styles.contact} ${styles.email}`}>{card.email}</p>
    </a>
  </div>
)

const IndexPage = () => (
  <div className={styles.container}>
    <Seo />
    <div className={styles.photoSection}>
      <StaticImage
        alt="Night scape"
        src="../images/background.png"
        // width={1500}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        layout="constrained"
        // objectFit="contain"
        objectPosition="left center" // important
        quality={100}
        style={{
          height: "100%",
        }}
      />
    </div>
    <div className={styles.nameSection}>
      <div className={styles.greenSection}>
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} />
        </div>
        <div className={styles.nameArticleWrapper}>
          <div className={styles.nameArticle}>
            <div className={styles.nameCardWrapper}>
              {nameCards.map(card => (
                <NameCard key={card.id} card={card} />
              ))}
            </div>
            <div className={styles.address}>
              <p>#110 (1F), Sunmyung Square,</p>
              <p>111, Seongsuil-ro, Seongdong-gu,</p>
              <p>Seoul, Republic of Korea</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.peopleTargetImg}>
        <StaticImage
          alt="Target people"
          src="../images/people.svg"
          formats={["auto", "webp", "avif"]}
          placeholder="blurred"
          // objectFit="fill"
          style={{
            width: `100%`,
            height: `100%`,
          }}
        />
      </div>
    </div>
  </div>
)

export default IndexPage
